<!-- 转运 by阿旭 -->
<template>
  <div>
    <div v-if="!isShowDetail">
      <el-tabs v-model="activeName" @tab-click="tabhandleClick">
        <!-- 待转运人员 -->
        <el-tab-pane label="待转运人员" name="first">
          <el-row type="flex" justify="space-between" align="middle">
            <el-col :span="20">
              <el-input style="width: 220px; margin-right: 20px" v-model="waitTransportForm.searchValue" clearable
                placeholder="居民姓名/手机号/证件号">
              </el-input>
              <el-select style="width: 220px; margin-right: 20px" v-model="waitTransportForm.crowdTypeId" clearable
                filterable placeholder="人群分类">
                <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName" :value="item.id">
                </el-option>
              </el-select>
              <el-select style="width: 220px; margin-right: 20px" v-model="waitTransportForm.eventId" clearable
                placeholder="事件名称">
                <el-option v-for="item in eventOptions" :key="item.id" :label="item.eventName" :value="item.id">
                </el-option>
              </el-select>
              <el-select style="width: 220px; margin-right: 20px" v-model="waitTransportForm.responsibleOrgCode"
                clearable filterable placeholder="负责机构">
                <el-option v-for="item in responsibleOrgCodeOptions" :key="item.orgCode" :label="item.orgName"
                  :value="item.orgCode">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4" style="text-align: right">
              <el-button type="primary" @click="queryPerson('quertBtn')">查询</el-button>
            </el-col>
          </el-row>
          <div class="top_table">
            <el-table :data="tableData" :loading="loading" style="width: 100%" height="600"
              @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column fixed="left" label="操作" width="150">
                <template slot-scope="scope">
                  <el-button @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
                  <el-button v-if="
                    scope.row.transportStatus == '0' ||
                    scope.row.transportStatus == '3'
                  " @click="cancelTransportReg(scope.row)" type="text" size="small">取消转运</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="transportFailTime" label="分配失败时间" width="160"></el-table-column>
              <el-table-column prop="transportFailReason" label="分配失败原因" width="160"></el-table-column>
              <el-table-column prop="name" label="姓名" width="120"></el-table-column>
              <el-table-column prop="cardType" label="证件类型" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row.cardType | typeid }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="cardNo" label="证件号码" width="180"></el-table-column>
              <el-table-column prop="linkPhone" label="联系电话" width="120"></el-table-column>
              <el-table-column prop="crowdTypeName" label="人群分类" width="160"></el-table-column>
              <el-table-column prop="surveyObjectBelongArea" label="所属地区" width="160"></el-table-column>
              <el-table-column prop="currentAddress" label="当前居住地址" width="200">
                <template slot-scope="scope">
                  <span v-if="scope.row.currentAddress != null">{{
                      scope.row.currentAddress
                  }}</span>
                  <span v-if="scope.row.currentAddressDetail != null">{{
                      scope.row.currentAddressDetail
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="surveyObjectBelongOrg" label="负责机构" width="160"></el-table-column>
              <el-table-column prop="regType" label="任务来源" width="160">
                <template slot-scope="scope">
                  <span v-if="scope.row.regType == '1'">疾控流调</span>
                  <span v-if="scope.row.regType == '2'">街镇排查</span>
                </template>
              </el-table-column>
              <el-table-column prop="surveyUserName" label="调查人员" width="120"></el-table-column>
              <el-table-column prop="surveyDate" label="调查日期" width="180"></el-table-column>
              <el-table-column prop="eventName" label="事件名称" width="120"></el-table-column>
            </el-table>
          </div>
          <div class="top_operate">
            <div style="display:flex;">
              <div style="margin-right: 20px">
                已选中{{ multipleSelection }}项目
              </div>
              <div style="margin-right: 20px">批量操作</div>
              <div style="margin-right: 20px">
                <el-button type="primary" :disabled="multipleSelection == 0" @click="auto">自动分配</el-button>
              </div>
              <div style="margin-right: 20px">
                <el-button type="warning" :disabled="multipleSelection == 0" @click="manual">手动分配</el-button>
              </div>
            </div>


            <div class="content_page1">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pageNumber" :total="total" :page-sizes="[50, 70, 90, 100]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" />
            </div>
          </div>
        </el-tab-pane>
        <!-- 待处理任务 -->
        <el-tab-pane label="待处理任务" name="second">
          <el-row type="flex" justify="space-between" align="middle">
            <el-col :span="22">
              <el-input style="width: 220px; margin-right: 20px" v-model="taskNo" placeholder="任务编号"></el-input>
              <el-date-picker style="width: 300px; margin-right: 20px" v-model="timevalue" type="daterange"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @onPick="onPick"
                value-format="yyyy-MM-dd"></el-date-picker>
              <el-select clearable filterable style="width: 230px; margin-right: 20px" v-model="responsibleOrgCode"
                placeholder="任务所属社区" @change="sqchange">
                <el-option v-for="item in responsibleOrgCodeOptions" :key="item.orgCode" :label="item.orgName"
                  :value="item.orgCode">
                </el-option>
              </el-select>
              <el-select style="width: 140px; margin-right: 20px" v-model="transportDriverId" clearable filterable
                placeholder="任务所属司机">
                <el-option v-for="item in sqsjoptionscx" :key="item.value" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-select style="width: 140px; margin-right: 20px" v-model="taskStatus" clearable placeholder="任务状态">
                <el-option v-for="item in rwoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-input style="width: 140px" v-model="names" placeholder="姓名" clearable></el-input>
            </el-col>
            <el-col :span="2" style="text-align: right">
              <el-button type="primary" @click="queryTask('btn')">查询</el-button>
            </el-col>
          </el-row>
          <div class="top_table">
            <el-table :data="tableData02" style="width: 100%" height="600" @expand-change="expandchange"
              :row-key="getRowKeys" :expand-row-keys="expands">
              <el-table-column type="expand">
                <template>
                  <table>
                    <tr v-for="(i, v) in ffdata" :key="v">
                      <td class="td0">
                        <span>{{ i.name }}</span>
                      </td>
                      <td class="td0">
                        <span :class="
                          i.crowdType == '6' || i.crowdType == '7'
                            ? 'dialog_msg_type'
                            : 'dialog_msg_typebsmj'
                        ">{{ i.crowdTypeName }}</span>
                      </td>
                      <td class="td2">{{ i.sex | sex }}</td>
                      <td class="td3">{{ i.age }}岁</td>
                      <td class="td4">{{ i.cardNo }}</td>
                      <td class="td5">{{ i.linkPhone }}</td>
                      <td class="td5">{{ i.receiveStatus | status }}</td>
                      <td class="td6">
                        <!-- 满足未接到、另行接送，并且人员未取消、任务为非取消，才展示取消 -->
                        <div style="color: #0d81fd; cursor: pointer" @click="handleClick(i)">
                          详情
                        </div>
                        <!-- <div v-if="taskStatus!=4&&i.receiveStatus == 0&&i.cancelStatus==1"
                          style="color:#E43223;margin-left:20px;cursor:pointer;" @click="newrw(i)">新建任务</div> -->
                        <div v-if="
                          taskStatus != 4 &&
                          (i.receiveStatus == 0 || i.receiveStatus == 2) &&
                          i.cancelStatus == 1
                        " style="
                            color: #797979;
                            margin-left: 20px;
                            cursor: pointer;
                          " @click="qxaa(i)">
                          取消
                        </div>
                      </td>
                    </tr>
                  </table>
                </template>
              </el-table-column>
              <el-table-column fixed="left" label="操作" width="100">
                <template slot-scope="scope">
                  <!-- 取消和完成状态无此操作按钮 -->
                  <el-button v-show="!['40', '50'].includes(scope.row.taskStatus)" @click="distribute(scope.row)"
                    type="text" size="small">
                    分配</el-button>
                  <el-button v-show="!['40', '50'].includes(scope.row.taskStatus)" @click="qxaaaa(scope.row)"
                    type="text" size="small" style="color: gray">取消
                  </el-button>
                  <el-button v-show="scope.row.unusualStatus == 1" type="text" size="small" style="color: red"
                    @click="confirmUnusual(scope.row.id)">确认异常</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="taskNo" label="任务编号" width="200"></el-table-column>
              <el-table-column prop="crtTime" label="任务下达时间" width="180"></el-table-column>

              <el-table-column prop="unusualStatus" label="任务状态" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.unusualStatus == 1">{{
                      scope.row.unusualStatus | typerw
                  }}</span>
                  <span v-if="scope.row.unusualStatus != 1">{{
                      scope.row.taskStatus | typerw
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="peopleNum" label="居民人数" width="120"></el-table-column>
              <el-table-column prop="driverRefuseRemark" label="原因" width="120">
                <template slot-scope="scope">
                  {{ scope.row | refuseRemark }}
                </template>
              </el-table-column>
              <el-table-column prop="receiveAddress" label="上车地点" width="180">
                <template slot-scope="scope">
                  <span v-if="scope.row.receiveAddress != null">{{
                      scope.row.receiveAddress
                  }}</span>
                  <span v-if="scope.row.receiveAddressDetail != null">{{
                      scope.row.receiveAddressDetail
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="responsibleOrgName" label="社区负责机构" width="140"></el-table-column>
              <el-table-column prop="responsibleOrgLeaderName" label="社区负责人" width="160">
                <template slot-scope="scope">
                  {{
                      scope.row.responsibleOrgLeaderName +
                      " " +
                      scope.row.leaderLinkPhone
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="transportDriverName" label="转运司机" width="160">
                <template slot-scope="scope">
                  {{
                      scope.row.transportDriverName +
                      " " +
                      scope.row.driverLinkPhone
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="isolationPointName" label="隔离点" width="200">
                <template slot-scope="scope">
                  {{
                      scope.row.isolationPointName + " " + scope.row.isonLinkPhone
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="content_page">
            <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
              :current-page="pageNumber" :total="total" :page-sizes="[50, 70, 90, 100]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" />
          </div>
        </el-tab-pane>
      </el-tabs>

      <!-- 手动分配输入信息弹框 -->
      <el-dialog title="任务分配" :visible.sync="ismanual" width="60%" @close="closeDialog" v-if="ismanual">
        <div>
          <span>任务信息：</span>
          <el-divider></el-divider>
          <div class="dialog_msg" v-for="(i, v) in checkdata" :key="v">
            <div class="dialog_msg_name">
              {{ i.name }}
              <span class="dialog_msg_name_id">({{ i.cardNo }})</span>
            </div>
            <div :class="
              i.crowdType == '6' || i.crowdType == '7'
                ? 'dialog_msg_type'
                : 'dialog_msg_typebsmj'
            ">
              {{ i.crowdTypeName }}
            </div>
            <div style="margin-right: 14px">{{ i.surveyObjectBelongOrg }}</div>
            <div style="margin-right: 14px">
              <span v-if="i.currentAddress != null">{{
                  i.currentAddress
              }}</span>
              <span v-if="i.currentAddressDetail != null">{{
                  i.currentAddressDetail
              }}</span>
            </div>
          </div>

          <el-divider></el-divider>
        </div>
        <div>
          分配信息：
          <span v-if="checkdata.length > 1 && !isSameCommunity"
            style="margin-left: 26px; color: red">注意：居民不在同一个社区卫生服务中心</span>
        </div>
        <div class="sdform">
          <el-form ref="manualDistributeFormRef" :rules="distributeRules" :model="sdform" label-width="140px">
            <el-form-item class="formitem" label="居民上车地点：" prop="jmscdd">
              <el-cascader style="width:300px" ref="currentAddressRef" class="select-width" v-model="sdform.jmscdd"
                :props="arearProps" @change="handelCurrentAddress" v-if="echoAddress">
              </el-cascader>
            </el-form-item>
            <!-- <el-form-item label="居民上车地点" prop="jmscdd">
              <el-select
                clearable
                filterable
                allow-create
                default-first-option
                v-model="sdform.jmscdd"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in checkdata"
                  :key="item.currentAddressCode"
                  :label="item.currentAddressDetail"
                  :value="item.currentAddressCode"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item prop="jmscdd2">
              <el-autocomplete style="width:300px" class="inline-input" v-model="sdform.jmscdd2"
                :fetch-suggestions="querySearch" placeholder="请输入居民上车地点" @select="handleSelect"></el-autocomplete>
            </el-form-item>
            <!-- <el-form-item prop="jmscdd">
              <el-input style="width:300px" v-model="sdform.jmscdd2" placeholder="请输入居民上车地点"></el-input>
            </el-form-item> -->
            <el-form-item label="所属社区" prop="region">
              <el-select clearable filterable v-model="sdform.region" placeholder="请选择" @change="sqchange">
                <el-option v-for="item in responsibleOrgCodeOptions" :key="item.value" :label="item.orgName"
                  :value="item.orgCode">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="转运人员（社区）" prop="zyregion">
              <el-select clearable filterable v-model="sdform.zyregion" placeholder="请选择" @change="zychange">
                <el-option v-for="item in sqryoptions" :key="item.value" :label="item.doctorName"
                  :value="item.doctorId">
                  <span style="float: left">{{ item.doctorName }}</span>
                  <span style="float: right; font-size: 13px">
                    {{ item.schedulingId != null ? "值班" : "" }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="转运人员（司机）" prop="sjjregion">
              <el-select clearable filterable v-model="sdform.sjjregion" placeholder="请选择" @change="zysjchange">
                <el-option v-for="item in sqsjoptions" :key="item.value" :label="item.doctorName"
                  :value="item.doctorId">
                  <span style="float: left">{{ item.doctorName }}</span>
                  <span style="float: right; font-size: 13px">
                    {{ item.schedulingId != null ? "值班" : "" }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="隔离酒店" prop="jdregion">
              <el-select clearable filterable v-model="sdform.jdregion" placeholder="请选择" @change="gljschange">
                <el-option v-for="item in gljdoptions" :key="item.value" :label="item.name" :value="item.id">
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; font-size: 13px">
                    {{ item.emptyRoomCount }}间
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="sdrwqx">取 消</el-button>
          <el-button type="primary" @click="handleCreateTransportTask">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 待处理任务重新分配弹框 -->
      <el-dialog title="任务分配" :visible.sync="isafresh" width="60%" @close="closeDialog" v-if="isafresh">
        <div>
          <span>任务信息：</span>
          <el-divider></el-divider>
          <div class="dialog_msg" v-for="(i, v) in ffdata" :key="v">
            <div class="dialog_msg_name">
              {{ i.name }}
              <span class="dialog_msg_name_id">({{ i.cardNo }})</span>
            </div>
            <div :class="
              i.crowdType == '6' || i.crowdType == '7'
                ? 'dialog_msg_type'
                : 'dialog_msg_typebsmj'
            ">
              {{ i.crowdTypeName }}
            </div>
            <div style="margin-right: 14px">{{ i.responsibleOrgName }}</div>
            <div style="margin-right: 14px">
              <span v-if="i.currentAddress != null">{{
                  i.currentAddress
              }}</span>
              <span v-if="i.currentAddressDetail != null">{{
                  i.currentAddressDetail
              }}</span>
            </div>
          </div>
          <el-divider></el-divider>
        </div>
        <div>分配信息：</div>
        <div class="sdform">
          <el-form ref="aginDistributeFormRef" :rules="distributeRules" :model="sdform" label-width="140px">
            <el-form-item class="formitem" label="居民上车地点：" prop="jmscdd">
              <el-cascader style="width:300px" ref="currentAddressRef" class="select-width" v-model="sdform.jmscdd"
                :props="arearProps" @change="handelCurrentAddress">
              </el-cascader>
            </el-form-item>
            <el-form-item prop="jmscdd2">
              <el-autocomplete style="width:300px" class="inline-input" v-model="sdform.jmscdd2"
                :fetch-suggestions="querySearch" placeholder="请输入居民上车地点" @select="handleSelect"></el-autocomplete>
            </el-form-item>
            <el-form-item label="所属社区" prop="region">
              <el-select filterable clearable v-model="sdform.region" placeholder="请选择" @change="sqchange">
                <el-option v-for="item in responsibleOrgCodeOptions" :key="item.value" :label="item.orgName"
                  :value="item.orgCode">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="转运人员（社区）" prop="zyregion">
              <el-select filterable clearable v-model="sdform.zyregion" placeholder="请选择" @change="zychange">
                <el-option v-for="item in sqryoptions" :key="item.value" :label="item.doctorName"
                  :value="item.doctorId">
                  <span style="float: left">{{ item.doctorName }}</span>
                  <span style="float: right; font-size: 13px">
                    {{ item.schedulingId != null ? "值班" : "" }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="转运人员（司机）" prop="sjjregion">
              <el-select filterable clearable v-model="sdform.sjjregion" placeholder="请选择" @change="zysjchange">
                <el-option v-for="item in sqsjoptions" :key="item.value" :label="item.doctorName"
                  :value="item.doctorId">
                  <span style="float: left">{{ item.doctorName }}</span>
                  <span style="float: right; font-size: 13px">
                    {{ item.schedulingId != null ? "值班" : "" }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="隔离酒店" prop="jdregion">
              <el-select filterable clearable v-model="sdform.jdregion" placeholder="请选择" @change="gljschange">
                <el-option v-for="item in gljdoptions" :key="item.value" :label="item.name" :value="item.id">
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; font-size: 13px">
                    {{ item.emptyRoomCount }}间
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cxfpp">取 消</el-button>
          <el-button type="primary" @click="manualqd">确 定</el-button>
        </span>
      </el-dialog>

      <!--分配配结果弹框 -->
      <dialog-info :dialogVisible="isauto" :title="distributionTitle" v-show="isauto" @closeDialog="closeAutoDialog"
        @confirmDialog="closeAutoDialog">
        <el-row class="warning" type="flex" align="middle" :gutter="10">
          <el-col v-if="distributionResult == 'ALL_SUCCESS'" :span="2"><i class="el-icon-circle-check"
              style="color: #19be6b; font-size: 28px; font-weight: bold"></i></el-col>
          <el-col v-else-if="distributionResult == 'ALL_FAIL'" :span="2"><i class="el-icon-circle-close"
              style="color: #f57a84; font-size: 28px; font-weight: bold"></i></el-col>
          <el-col v-else-if="distributionResult == 'PART_FAIL'" :span="2"><i class="el-icon-warning-outline"
              style="color: #ff9900; font-size: 28px; font-weight: bold"></i></el-col>
          <el-col :span="12"><span style="font-weight: bold; font-size: 16px">{{
              distributionMessage
          }}</span></el-col>
        </el-row>
        <el-row>
          <el-col :offset="2"><span>{{ distributionRemark }}</span></el-col>
        </el-row>
      </dialog-info>
      <!-- 确认异常弹框 -->
      <dialog-info :dialogVisible="isUnusual" title="操作确定" :isShowCancel="true" @closeDialog="closeUnusualDialog"
        @cancelDialog="closeUnusualDialog" @confirmDialog="confirmUnusualDialog">
        <el-row class="warning" type="flex" align="middle" :gutter="10">
          <el-col :span="2"><i class="el-icon-question" style="color: #ff9900; font-size: 28px; font-weight: bold"></i>
          </el-col>
          <el-col :span="12"><span style="font-weight: bold; font-size: 16px">确定要标记异常状态任务为完成吗？</span></el-col>
        </el-row>
      </dialog-info>
      <!-- 整个任务取消弹框 -->
      <el-dialog title="操作确认" :visible.sync="cancleTaskVisible" width="40%">
        <div>
          <span style="color: red; margin-right: 8px">*</span>取消原因：
          <br />
          <br />
          <el-radio-group v-model="taskCancelReason" @change="radioo">
            <el-radio :label="1">自行到隔离点</el-radio>
            <el-radio :label="2">人员重新分配</el-radio>
            <el-radio :label="3">其他</el-radio>
          </el-radio-group>
          <el-input v-show="taskCancelReason == 3" style="margin-top: 20px" v-model="iccnput" placeholder="请输入内容">
          </el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeCancelTaskDialog">取 消</el-button>
          <el-button type="primary" @click="cancelTask">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 单人取消弹框 -->
      <el-dialog title="操作确认" :visible.sync="cancelPersonVisible" width="40%">
        <div>
          <span style="color: red; margin-right: 8px">*</span>取消原因：
          <br />
          <br />
          <el-radio-group v-model="taskCancelReason" @change="radioo">
            <el-radio :label="1">自行到隔离点</el-radio>
            <el-radio :label="2">人员重新分配</el-radio>
            <el-radio :label="3">其他</el-radio>
          </el-radio-group>
          <el-input v-show="taskCancelReason == 3" style="margin-top: 20px" v-model="iccnput" placeholder="请输入内容">
          </el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeCancelPersonDialog">取 消</el-button>
          <el-button type="primary" @click="cancelPerson">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>
  </div>
</template>

<script>
import residentInformation from "@/views/QuarantineRegistration/residentInformation";
import TableModel from "@/components/TableModel";
import { mapState } from "vuex";
import {
  getWaitTransportPersonList,
  cancelTransportRegById,
  autoInitTransportRegTask,
  getAllUserScheduling,
  createTransportTask,
  getWaitTaskList,
  getTransportRegTaskListByTaskId,
  updateTransportTask,
  cancelTaskById,
  cancelSurveyTaskById,
  transportTaskInfoApi,
} from "@/api/Taskmanagement/upcomingTasks.js";
import {
  getEventListApi,
  confirmExceptionApi,
} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import {
  getBusinessOrgList,
  getIsolationEmptyRoomList,
  getUserListByPeopleTypeApi,
} from "@/api/SystemManagement/index";
import DialogInfo from "@/components/DialogInfo.vue";
import { http } from "@/api/index";
import { timeStamp } from "console";
export default {
  components: { residentInformation, TableModel, DialogInfo },
  data() {
    return {
      echoAddress:false,
      addForm: { name: "" },
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      waitTransportForm: {
        searchValue: "",
        crowdTypeId: "",
        eventId: "",
        responsibleOrgCode: "",
      },
      activeName: "first",
      multipleSelection: 0,
      isShowDetail: false,
      taskStatus: 1,
      refresh: false,
      jmname: "",
      ffdata: [],
      eventOptions: [],
      responsibleOrgCodeOptions: [],
      taskNo: "",
      timevalue: ["", ""],
      sqsjoptions: "",

      isafresh: false,
      cancleTaskVisible: false,
      isinput: false,
      sqryoptions: "",
      sddata: [],
      gljdoptions: "",
      value1: "",
      taskCancelReason: "",
      sdform: {
        jmscdd: [],
        jmscdd2: ''
      }, //手动分配表单
      responsibleOrgCode: "",
      transportDriverId: "",
      value: "",
      isauto: false, //是否显示自动分配结果弹框
      distributionResult: "", //自动分配结果
      distributionTitle: "", //自动分配标题
      distributionMessage: "", //自动分配弹框内容
      distributionRemark: "", //自动分配备注
      ismanual: false, //是否显示手动分配输入转运信息框
      distributeRules: {
        jmscdd: [
          { required: true, message: "请选择居民上车地点", trigger: "change" },
        ],
        region: [
          { required: true, message: "请选择所属社区", trigger: "change" },
        ],
        zyregion: [
          {
            required: true,
            message: "请选择转运人员(社区)",
            trigger: "change",
          },
        ],
        sjjregion: [
          {
            required: true,
            message: "请选择转运人员（司机）",
            trigger: "change",
          },
        ],
        jdregion: [
          { required: true, message: "请选择隔离酒店", trigger: "change" },
        ],
      },
      checkdata: [], //待转运人员数组
      isSameCommunity: false, //选择的人员是否为同一个社区
      tableList: [],
      grrw: 0,
      tableData02: [],
      sxdata01: "",
      tableData: [],
      jmscoptions: [],
      sqsjoptionscx: [],
      zheh: "",
      getRowKeys(row) {
        // 行数据的Key
        return row.id;
      },
      expands: [],
      iccnput: "",
      cancelPersonVisible: false,
      grqxa: "",
      rwoptions: [
        {
          label: "待处理中",
          value: 1,
        },
        {
          label: "执行中",
          value: 2,
        },
        {
          label: "完成",
          value: 3,
        },
        {
          label: "取消",
          value: 4,
        },
      ],
      loading: false,
      pageNumber: 1,
      pageSize: 50,
      total: 0,
      names: "", //待处理任务姓名搜索框
      isUnusual: false, //确认异常弹框
      //4级
      arearProps: {
        lazy: true,
        lazyLoad(node, resolve) {
          // console.log(node,resolve,'输出node，resolve1111111111')
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 1000);
          } else {
            let formm = { label: node.data.value };
            // console.log(formm,'输出formm22222222222222')
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 4,
              }));
              resolve(nodes);
            });
          }
        },
      },
      dizhiCode: [],
      xiangxidizhi: '',
      restaurants: [],
      receiveAddress: '',
      form: {
        bdTuid: '',
        gdTuid: ''
      },
      currentAddress1: ''
    };
  },
  filters: {
    typeid(val) {
      if (val == 1) {
        val = "居民身份证";
        return val;
      } else if (val == 2) {
        val = "护照";
        return val;
      } else if (val == 3) {
        val = "港澳通行证";
        return val;
      } else if (val == 4) {
        val = "台胞证";
        return val;
      } else if (val == 5) {
        val = "其他";
        return val;
      }
    },
    typerw(val) {
      if (val == 10) {
        val = "社区待接收任务";
        return val;
      } else if (val == 11) {
        val = "社区拒绝任务";
        return val;
      } else if (val == 15) {
        val = "司机待接收";
        return val;
      } else if (val == 16) {
        val = "司机拒绝任务";
        return val;
      } else if (val == 20) {
        val = "司机待处理";
        return val;
      } else if (val == 25) {
        val = "司机处理中";
        return val;
      } else if (val == 30) {
        val = "等待上车";
        return val;
      } else if (val == 35) {
        val = "全部送往隔离点";
        return val;
      } else if (val == 36) {
        val = "部分送往隔离点";
        return val;
      } else if (val == 40) {
        val = "完成任务";
        return val;
      } else if (val == 50) {
        val = "取消";
        return val;
      } else if (val == 0) {
        val = "正常";
        return val;
      } else if (val == 1) {
        val = "异常";
        return val;
      }
    },
    sex(val) {
      if (val == 1) {
        val = "男";
        return val;
      } else {
        val = "女";
        return val;
      }
    },
    status(val) {
      if (val == 0) {
        val = "未接到";
        return val;
      } else if (val == 1) {
        val = "已接到";
        return val;
      } else if (val == 2) {
        val = "再行接送";
        return val;
      } else if (val == 3) {
        val = "自行到隔离点";
        return val;
      }
    },
    //待处理任务拒绝原因
    refuseRemark(val) {
      if (val.taskStatus == 11) {
        return val.communityRefuseRemark;
      }
      if (val.taskStatus == 16) {
        return val.driverRefuseRemark;
      }
    },
  },
  computed: {
    ...mapState({
      typepopSelect: (state) => state.user.typepopSelect, // 人群分类
    }),
  },
  created() {
    this.queryPerson();
    this.getEventList();
    this.getIsolationEmptyRoomList();
    this.getUserListByPeopleType();
    this.getBusinessOrgList();
  },
  mounted() {
    // this.sdform.jmscdd  = this.$store.state.defaultAddress.addressFourCode.split(',');
    // console.log(this.$store.state.defaultAddress.addressFourCode.split(','))
  },

  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      this.sdform.jmscdd2 = item.value
      console.log(item);
    },
    //现住地
    handelCurrentAddress(value) {
      console.log("选择完成时触发", value);
      // console.log(value, 'value是11111111111111111111111111111区域编码');
      let node = this.$refs.currentAddressRef.getCheckedNodes()[0];
      let adr = node.pathLabels.join('/')
      console.log('66666');
      console.log(node);
      if (node) {
        let currentAddress = node.pathLabels.toString();
        // this.regitsterInfo.currentAddress = currentAddress;
        this.sdform.jmscdd = value;
        console.log(this.sdform.jmscdd);
        this.receiveAddress = adr
        console.log(this.receiveAddress);
        // console.log(
        //   this.regitsterInfo.currentAddressCode,
        //   "打印出codecodecode"
        // );
      }
    },
    //分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPerson();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.queryPerson();
    },
    // 根据业务状态查询业务机构列表(查询转运任务业务机构列表)
    async getBusinessOrgList() {
      let params = {
        transshipmentTask: 1,
        orgCode: this.userdoctor.orgCode,
      };
      let { data } = await getBusinessOrgList({ params });
      if (data.code == "200") {
        this.responsibleOrgCodeOptions = data.data;
      }
    },
    tabhandleClick(tab, event) {
      if (tab.index == 0) {
        this.queryPerson();
      } else {
        this.queryTask();
      }
    },
    //取消转运登记
    cancelTransportReg(row) {
      this.$confirm(`确定要取消转运此人【${row.name}】吗？`, "取消转运", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          cancelTransportRegById({ id: row.id }).then((res) => {
            let { code } = res.data;
            if (code == 200) {
              this.$message({
                type: "success",
                message: "取消转运成功!",
              });
              this.queryPerson();
            }
          });
        })
        .catch(() => { });
    },
    //待转运人员处理选择框
    handleSelectionChange(val) {
      this.multipleSelection = val.length;
      this.checkdata = val;
      console.log(val);
      if (this.checkdata.length == 1) {
        console.log('666');
        this.dizhiCode = []
        this.xiangxidizhi = ''
        this.dizhiCode = this.checkdata[0].currentAddressCode.split(',')
        this.xiangxidizhi = val[0].currentAddressDetail
        this.receiveAddress = val[0].currentAddress
        console.log(this.dizhiCode);
      } else if (this.checkdata.length == 0) {
        this.dizhiCode = []
        this.xiangxidizhi = ''
      }
      else {
        this.dizhiCode = []
        this.xiangxidizhi = ''
        let newArr1 = []
        this.checkdata.forEach(res => {
          newArr1.push({
            value: res.currentAddressDetail
          })
        })
        this.restaurants = newArr1
        // isSameCommunity
        const currentAddressCode =
          this.checkdata[0].currentAddressCode;
        this.isSameCommunity = this.checkdata.every((item) => {
          return item.currentAddressCode == currentAddressCode;
        });
        console.log('666666666');
        console.log(this.isSameCommunity);
        if (this.isSameCommunity) {
          console.log(1);
          this.dizhiCode = this.checkdata[0].currentAddressCode.split(',')
          this.xiangxidizhi = val[0].currentAddressDetail
        } else {
          console.log(2);
          this.dizhiCode = []
          this.xiangxidizhi = ''
        }
        // this.checkdata.forEach()
      }

    },
    //待转运人员手动分配
    manual() {
      this.sdform = {
        jmscdd: this.dizhiCode,
        jmscdd2: this.xiangxidizhi,
        region: "",
        zyregion: "",
        sjjregion: "",
        jdregion: "",
      };
      console.log(this.sdform);
      this.ismanual = true;
      this.echoAddress = true;
      const currentAddressCode =
        this.checkdata[0].currentAddressCode;
      const surveyObjectBelongOrgCode =
      this.checkdata[0].surveyObjectBelongOrgCode;
      this.isSameCommunity = this.checkdata.every((item) => {
        return item.currentAddressCode == currentAddressCode;
      });
      //所选人员机构相同，默认选择该机构
      if (this.isSameCommunity || this.checkdata.length == 1) {
        this.sdform.region = surveyObjectBelongOrgCode;
        //加载运转人员
        this.sqchange(surveyObjectBelongOrgCode);
      }
    },
    //自动分配
    auto() {
      if (this.checkdata.length == 0) {
        this.$message({
          showClose: true,
          message: "请勾选待转运人员",
          type: "error",
        });
      } else {
        this.handleAutoInitTransport();
      }
    },
    // 查询待处理任务
    async queryTask(type) {
      if (type == "btn") {
        this.pageNumber = 1;
      }
      let a, b;
      if (this.timevalue == null) {
        this.timevalue = [];
      } else {
        if (this.timevalue[0]) {
          a = this.timevalue[0];
          b = this.timevalue[1];
        }
      }
      // if (this.names) {
      //   this.pageNumber = 1;
      // }
      let params = {
        taskNo: this.taskNo,
        taskCreateStartTime: a,
        taskCreateEndTime: b,
        responsibleOrgCode: this.responsibleOrgCode,
        transportDriverId: this.transportDriverId,
        taskStatus: this.taskStatus,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        names: this.names,
      };
      const res = await getWaitTaskList({ params });
      if (res.data.code == "200") {
        this.tableData02 = res.data.data.records;
        console.log(this.tableData02);
        this.total = parseInt(res.data.data.total);
      }
    },
    // 查询待转运人员
    async queryPerson(v) {
      if (v == 'queryBtn') {
        this.pageNumber = 1;
      }

      this.loading = true;
      let params = {
        searchValue: this.waitTransportForm.searchValue,
        crowdTypeId: this.waitTransportForm.crowdTypeId,
        eventId: this.waitTransportForm.eventId,
        responsibleOrgCode: this.waitTransportForm.responsibleOrgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      const res = await getWaitTransportPersonList({ params });
      if (res.data.code == "200") {
        this.tableData = res.data.data.list;
        this.total = res.data.data.totalRow;
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
    },
    //待处理任务中分配
    async manualqd() {
      // 单人新建任务
      if (this.grrw == 1) {
        let idd = [];
        for (let i in this.ffdata) {
          if (this.ffdata[i].id != undefined) {
            idd.push(this.ffdata[i].id);
          }
        }
        // 上车地点
        let receiveAddress,
          receiveAddressBdTuid,
          receiveAddressCode,
          receiveAddressDetail,
          receiveAddressGdTuid;

        // for (let i in this.ffdata) {
        //   if (this.ffdata[i].id == this.sdform.jmscdd) {
        //     receiveAddress = this.ffdata[i].currentAddress;
        //     receiveAddressBdTuid = this.ffdata[i].currentAddressBdTuid;
        //     receiveAddressCode = this.ffdata[i].currentAddressCode;
        //     receiveAddressDetail = this.ffdata[i].currentAddressDetail;
        //     receiveAddressGdTuid = this.ffdata[i].currentAddressGdTuid;
        //   }else{
        //     receiveAddress = this.receiveAddress
        //     receiveAddressCode = this.sdform.jmscdd.join(',')
        //     receiveAddressDetail = this.sdform.jmscdd2
        //   }
        // }
        this.receiveAddress = this.receiveAddress.split('/')
        receiveAddress = this.receiveAddress.join(',')
        receiveAddressCode = this.sdform.jmscdd.join(',')
        receiveAddressDetail = this.sdform.jmscdd2
        this.getBmap(receiveAddressDetail)
        receiveAddressBdTuid = this.form.bdTuid;
        receiveAddressGdTuid = this.form.gdTuid
        let params = {
          ids: idd,
          communityName: this.communityName,
          communityOrgCode: this.communityOrgCode,
          communityOrgId: this.communityOrgId,
          communityUser: this.communityUser,
          communityUserId: this.communityUserId,
          driverUser: this.driverUser,
          driverUserId: this.driverUserId,
          isolationPointName: this.isolationPointName,
          isolationPointId: this.isolationPointId,
          isolationPointAddress: this.isolationPointAddress,
          taskId: this.zheh.id,
          receiveAddress: receiveAddress,
          receiveAddressBdTuid: receiveAddressBdTuid,
          receiveAddressCode: receiveAddressCode,
          receiveAddressDetail: receiveAddressDetail,
          receiveAddressGdTuid: receiveAddressGdTuid,
        };
        const { data } = await createTransportTask(params);
        if (data.code == "200") {
          this.isafresh = false;
          this.sdform = {};
          this.distributionResult = data.data.code;
          switch (this.distributionResult) {
            case "ALL_SUCCESS":
              this.distributionTitle = "操作成功";
              this.distributionMessage = `任务成功分配${data.data.arrangeSuccessSize}人`;
              this.distributionRemark = "已消息通知相关司机、社区、酒店人员";
              break;
            case "PART_FAIL":
              this.distributionTitle = "警告提示";
              this.distributionMessage = `任务成功分配${data.data.arrangeSuccessSize}人,失败${data.data.arrangeFailList}人`;
              this.distributionRemark = "具体查看分配失败原因";
              break;
            case "ALL_FAIL":
              this.distributionTitle = "错误提示";
              this.distributionMessage = `任务发送失败${data.data.arrangeFailList}人`;
              this.distributionRemark = "具体查看分配失败原因";
              break;
            default:
              break;
          }
        }
      } else {
        // 任务分配
        this.updateTransportTask();
      }
    },
    //待处理任务的分配按钮
    distribute(row) {
      console.log(row,'打印rowwwww');
      this.grrw = 0;
      this.zheh = row;
      this.isafresh = true;
      //查询待处理任务中人员信息
      this.expandchange02(row);
      //查询待处理任务中转运信息
      this.transportTaskInfo(row.id);
    },
    qxaaaa(row) {
      this.zheh = row;
      this.cancleTaskVisible = true;
    },
    radioo(v) {
      if (v == 3) {
        this.isinput = !this.isinput;
      } else {
        this.isinput = false;
      }
    },
    // 事件列表
    async getEventList() {
      const { data } = await getEventListApi();
      if (data.code == 200) {
        this.eventOptions = data.data;
      }
    },
    // 自动分配
    async handleAutoInitTransport() {
      let idd = [];
      for (let i in this.checkdata) {
        if (this.checkdata[i].id) {
          idd.push(this.checkdata[i].id);
        }
      }
      let params = {
        ids: idd,
      };
      const { data } = await autoInitTransportRegTask(params);
      if (data.code == 200) {
        this.isauto = true;
        this.distributionResult = data.data.code;
        switch (this.distributionResult) {
          case "ALL_SUCCESS":
            this.distributionTitle = "操作成功";
            this.distributionMessage = `任务成功分配${data.data.arrangeSuccessSize}人`;
            this.distributionRemark = "已消息通知相关司机、社区、酒店人员";
            break;
          case "PART_FAIL":
            this.distributionTitle = "警告提示";
            this.distributionMessage = `任务成功分配${data.data.arrangeSuccessSize}人,失败${data.data.arrangeFailList}人`;
            this.distributionRemark = "具体查看分配失败原因";
            break;
          case "ALL_FAIL":
            this.distributionTitle = "错误提示";
            this.distributionMessage = `任务发送失败${data.data.arrangeFailList}人`;
            this.distributionRemark = "具体查看分配失败原因";
            break;
          default:
            break;
        }
      }
    },
    //关闭自动分配弹框
    closeAutoDialog() {
      this.isauto = false;
      this.distributionTitle = "";
      this.distributionMessage = "";
      this.distributionRemark = "";
      this.distributionResult = "";
      this.queryPerson();
    },
    // 待处理查询司机
    getUserListByPeopleType() {
      let params = {
        peopleType: 2,
        orgCode: this.userdoctor.orgCode,
      };
      getUserListByPeopleTypeApi({ params }).then((res) => {
        this.sqsjoptionscx = res.data.data;
      });
    },
    // 隔离酒店
    getIsolationEmptyRoomList() {
      getIsolationEmptyRoomList().then((res) => {
        // console.log("res");
        // console.log(res);
        this.gljdoptions = res.data.data;
      });
    },
    //所属社区事件
    sqchange(orgCode) {
      //转运人员清空
      this.sdform.zyregion = "";
      this.sdform.sjjregion = "";
      this.sxdata01 = orgCode;
      for (let i in this.responsibleOrgCodeOptions) {
        if (this.responsibleOrgCodeOptions[i].orgCode == orgCode) {
          this.communityName = this.responsibleOrgCodeOptions[i].orgName;
          this.communityOrgCode = this.responsibleOrgCodeOptions[i].orgCode;
          this.communityOrgId = this.responsibleOrgCodeOptions[i].id;
        }
      }
      let params = {
        schedulingType: this.$store.state.dict.schedulingType[0].key,
        responsibleOrgCode: orgCode,
      };
      // 社区人员
      getAllUserScheduling({ params }).then((res) => {
        if (res.data.data) {
          this.sqryoptions = res.data.data;
        } else {
          //清除转运人员(社区)信息
          this.sqryoptions = [];
          this.sdform.zyregion = "";
        }
      });
      this.getDriverDuty();
    },
    // 社区司机
    getDriverDuty() {
      let params = {
        schedulingType: this.$store.state.dict.schedulingType[1].key,
      };
      getAllUserScheduling({ params }).then((res) => {
        this.sqsjoptions = res.data.data ? res.data.data : [];
      });
    },
    // 转运人员选择
    zychange(v) {
      for (let i in this.sqryoptions) {
        if (this.sqryoptions[i].doctorId == v) {
          this.communityUser = this.sqryoptions[i].doctorName;
          this.communityUserId = this.sqryoptions[i].doctorId;
        }
      }
    },
    // 转运司机选择
    zysjchange(v) {
      for (let i in this.sqsjoptions) {
        if (this.sqsjoptions[i].doctorId == v) {
          this.driverUser = this.sqsjoptions[i].doctorName;
          this.driverUserId = this.sqsjoptions[i].doctorId;
        }
      }
    },
    // 隔离酒店选择
    gljschange(v) {
      for (let i in this.gljdoptions) {
        if (this.gljdoptions[i].id == v) {
          this.isolationPointName = this.gljdoptions[i].name;
          this.isolationPointId = this.gljdoptions[i].id;
          this.isolationPointAddress = this.gljdoptions[i].address;
        }
      }
    },
    // 手动分配确认
    handleCreateTransportTask() {
      this.$refs.manualDistributeFormRef.validate(async (valid) => {
        if (!valid) return;
        let idd = [];
        for (let i in this.checkdata) {
          if (this.checkdata[i].id) {
            idd.push(this.checkdata[i].id);
          }
        }
        // 上车地点
        let receiveAddress,
          receiveAddressBdTuid,
          receiveAddressCode,
          receiveAddressDetail,
          receiveAddressGdTuid;
        // for (let i in this.checkdata) {
        //   if (this.checkdata[i].currentAddressCode == this.sdform.jmscdd) {
        //     receiveAddress = this.checkdata[i].currentAddress;
        //     receiveAddressBdTuid = this.checkdata[i].currentAddressBdTuid;
        //     receiveAddressCode = this.checkdata[i].currentAddressCode;
        //     receiveAddressDetail = this.checkdata[i].currentAddressDetail;
        //     receiveAddressGdTuid = this.checkdata[i].currentAddressGdTuid;
        //   }else{
        //     receiveAddress = this.receiveAddress
        //     receiveAddressCode = this.sdform.jmscdd.join(',')
        //     receiveAddressDetail = this.sdform.jmscdd2
        //   }
        // }

        this.receiveAddress = this.receiveAddress.split('/')
        receiveAddress = this.receiveAddress.join(',')
        receiveAddressCode = this.sdform.jmscdd.join(',')
        receiveAddressDetail = this.sdform.jmscdd2
        this.getBmap(receiveAddressDetail)
        receiveAddressBdTuid = this.form.bdTuid;
        receiveAddressGdTuid = this.form.gdTuid
        let params = {
          ids: idd,
          communityName: this.communityName,
          communityOrgCode: this.communityOrgCode,
          communityOrgId: this.communityOrgId,
          communityUser: this.communityUser,
          communityUserId: this.communityUserId,
          driverUser: this.driverUser,
          driverUserId: this.driverUserId,
          isolationPointName: this.isolationPointName,
          isolationPointId: this.isolationPointId,
          isolationPointAddress: this.isolationPointAddress,
          receiveAddress: receiveAddress,
          receiveAddressBdTuid: receiveAddressBdTuid,
          receiveAddressCode: receiveAddressCode,
          receiveAddressDetail: receiveAddressDetail,
          receiveAddressGdTuid: receiveAddressGdTuid,
        };
        const { data } = await createTransportTask(params);
        if (data.code == 200) {
          this.ismanual = false;
          this.echoAddress = false
          this.isauto = true;
          this.distributionResult = data.data.code;
          switch (this.distributionResult) {
            case "ALL_SUCCESS":
              this.distributionTitle = "操作成功";
              this.distributionMessage = `任务成功分配${data.data.arrangeSuccessSize}人`;
              this.distributionRemark = "已消息通知相关司机、社区、酒店人员";
              break;
            case "PART_FAIL":
              this.distributionTitle = "警告提示";
              this.distributionMessage = `任务成功分配${data.data.arrangeSuccessSize}人,失败${data.data.arrangeFailList}人`;
              this.distributionRemark = "具体查看分配失败原因";
              break;
            case "ALL_FAIL":
              this.distributionTitle = "错误提示";
              this.distributionMessage = `任务发送失败${data.data.arrangeFailList}人`;
              this.distributionRemark = "具体查看分配失败原因";
              break;
            default:
              break;
          }
        }
      });
    },
    onPick(maxDate, minDate) {
      // console.log("maxDate", maxDate);
      // console.log("maxDate", minDate);
    },
    expandchange(row, expandedRows) {
      this.ffdata = [];
      this.zheh = row;

      let params = {
        transportTaskId: row.id,
      };
      getTransportRegTaskListByTaskId({ params }).then((res) => {
        this.ffdata = res.data.data;
        this.expands = [];
        if (expandedRows.length > 0) {
          row ? this.expands.push(row.id) : "";
        }
      });

      this.$forceUpdate();
    },
    //查询 待处理任务中单条任务的人员信息
    expandchange02(row) {
      let params = {
        transportTaskId: row.id,
      };
      getTransportRegTaskListByTaskId({ params }).then((res) => {
        this.ffdata = res.data.data;
      });
    },
    //查询 待处理任务中单条任务的旧分配信息
    async transportTaskInfo(id) {
      const params = { id: id };
      const { data } = await transportTaskInfoApi({ params });
      if (data.code == "200") {
        this.sdform = {
          jmscdd: data.data.receiveAddressCode.split(','),
          jmscdd2: data.data.receiveAddressDetail,
          region: data.data.responsibleOrgName,
          zyregion: data.data.responsibleOrgLeaderName,
          sjjregion: data.data.transportDriverName,
          jdregion: data.data.isolationPointName,
        };
        console.log(this.sdform);
        this.isolationPointName = data.data.isolationPointName;
        this.isolationPointId = data.data.isolationPointId;
        this.isolationPointAddress = data.data.isolationPointAddress;
        this.driverUser = data.data.transportDriverName;
        this.driverUserId = data.data.transportDriverId;
        this.communityUser = data.data.responsibleOrgLeaderName;
        this.communityUserId = data.data.responsibleOrgLeaderId;
        this.communityName = data.data.responsibleOrgName;
        this.communityOrgCode = data.data.responsibleOrgCode;
      }
    },
    //获取百度经纬度
    getBmap(value) {
      this.$jsonp("https://api.map.baidu.com/geocoding/v3", {
        address: value,
        output: "json",
        ak: "K47zbc0avMITSmQCoPu3oU9Eg9HLsII4",
      })
        .then((json) => {
          console.log(22222222, json);
          this.form.bdTuid =
            json.result.location.lng + "," + json.result.location.lat;
          const currentAddressGdTuid = this.bMapTransqqMap(
            json.result.location.lng,
            json.result.location.lat
          );
          this.form.gdTuid =
            currentAddressGdTuid.lng + "," + currentAddressGdTuid.lat;
        })
        .catch((err) => {
          console.log(err);
          this.form.bdTuid = '';
          this.form.gdTuid = '';
        });
    },
    //百度转高德
    bMapTransqqMap(lng, lat) {
      const X_PI = (Math.PI * 3000.0) / 180.0;
      let x = lng - 0.0065;
      let y = lat - 0.006;
      let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
      let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
      let amap_lng = z * Math.cos(theta);
      let amap_lat = z * Math.sin(theta);
      return {
        lng: amap_lng,
        lat: amap_lat,
      };
    },
    // 任务重新分配
    updateTransportTask() {
      this.$refs.aginDistributeFormRef.validate(async (valid) => {
        if (!valid) return;
        let idd = [];
        for (let i in this.ffdata) {
          if (this.ffdata[i].id != undefined) {
            idd.push(this.ffdata[i].id);
          }
        }
        // 上车地点
        let receiveAddress,
          receiveAddressBdTuid,
          receiveAddressCode,
          receiveAddressDetail,
          receiveAddressGdTuid;

        // for (let i in this.ffdata) {
        //   if (this.ffdata[i].currentAddressDetail == this.sdform.jmscdd) {
        //     receiveAddress = this.ffdata[i].currentAddress;
        //     receiveAddressBdTuid = this.ffdata[i].currentAddressBdTuid;
        //     receiveAddressCode = this.ffdata[i].currentAddressCode;
        //     receiveAddressDetail = this.ffdata[i].currentAddressDetail;
        //     receiveAddressGdTuid = this.ffdata[i].currentAddressGdTuid;
        //   }else{
        //     receiveAddress = this.receiveAddress
        //     receiveAddressCode = this.sdform.jmscdd.join(',')
        //     receiveAddressDetail = this.sdform.jmscdd2
        //   }
        // }
        this.receiveAddress = this.receiveAddress.split('/')
        receiveAddress = this.receiveAddress.join(',')
        receiveAddressCode = this.sdform.jmscdd.join(',')
        receiveAddressDetail = this.sdform.jmscdd2
        this.getBmap(receiveAddressDetail)
        receiveAddressBdTuid = this.form.bdTuid;
        receiveAddressGdTuid = this.form.gdTuid
        // receiveAddressCode = this.ffdata[i].currentAddressCode;
        // receiveAddressGdTuid = this.ffdata[i].currentAddressGdTuid;
        let params = {
          ids: idd,
          communityName: this.communityName,
          communityOrgCode: this.communityOrgCode,
          communityOrgId: this.communityOrgId,
          communityUser: this.communityUser,
          communityUserId: this.communityUserId,
          driverUser: this.driverUser,
          driverUserId: this.driverUserId,
          isolationPointName: this.isolationPointName,
          isolationPointId: this.isolationPointId,
          isolationPointAddress: this.isolationPointAddress,
          taskId: this.zheh.id,
          receiveAddress: receiveAddress,
          receiveAddressBdTuid: receiveAddressBdTuid,
          receiveAddressCode: receiveAddressCode,
          receiveAddressDetail: receiveAddressDetail,
          receiveAddressGdTuid: receiveAddressGdTuid,
        };
        updateTransportTask(params).then((res) => {
          if (res.data.code == 200) {
            this.isafresh = false;
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.queryTask();
          }
        });
      });
    },
    //任务取消
    async cancelTask() {
      if (!this.taskCancelReason) {
        this.$message({
          message: "必须选择取消原因",
          type: "warning",
        });
        return;
      }
      if (this.taskCancelReason == 3 && !this.iccnput) {
        this.$message({
          message: "请输入原因",
          type: "warning",
        });
        return;
      }
      let params = {
        taskId: this.zheh.id,
        taskCancelReason: this.taskCancelReason,
        taskCancelReasonOther: this.iccnput,
      };
      const { data } = await cancelTaskById({ params });
      if (data.code == 200) {
        this.$message({
          message: "取消成功！",
          type: "success",
        });
        this.cancleTaskVisible = false;

        if (this.taskCancelReason == "2") {
          this.activeName = "first";
          this.queryPerson();
        } else {
          this.queryTask();
        }
        this.taskCancelReason = "";
        this.iccnput = "";
      }
    },
    newrw(i) {
      this.grrw = 1;
      // console.log("i", i);
      this.isafresh = true;
      this.ffdata = [];
      this.ffdata.push(i);
    },
    qxaa(i) {
      this.grqxa = i;
      this.cancelPersonVisible = true;
    },
    // 单人取消
    cancelPerson() {
      if (!this.taskCancelReason) {
        this.$message({
          message: "必须选择取消原因",
          type: "warning",
        });
        return;
      }
      if (this.taskCancelReason == 3 && !this.iccnput) {
        this.$message({
          message: "请输入原因",
          type: "warning",
        });
        return;
      }

      let params = {
        taskId: this.zheh.id,
        cancelReason: this.taskCancelReason,
        cancelReasonOther: this.iccnput,
        transportRegId: this.grqxa.id,
        name: this.grqxa.name,
      };
      cancelSurveyTaskById({ params }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "取消成功！",
            type: "success",
          });
          this.cancelPersonVisible = false;
          if (this.taskCancelReason == "2") {
            this.activeName = "first";
            this.queryPerson();
          } else {
            this.queryTask();
          }
          this.taskCancelReason = "";
          this.iccnput = "";
        }
      });
    },
    //关闭任务取消对话框
    closeCancelTaskDialog() {
      this.cancleTaskVisible = false;
      this.iccnput = "";
      this.taskCancelReason = "";
    },
    //关闭人员取消对话框
    closeCancelPersonDialog() {
      this.cancelPersonVisible = false;
      this.iccnput = "";
      this.taskCancelReason = "";
    },
    //弹窗关闭时设置
    closeDialog() {
      this.$refs.manualDistributeFormRef?.resetFields();
      this.$refs.aginDistributeFormRef?.resetFields();
    },
    //手动分配弹框取消按钮
    sdrwqx() {
      this.$refs.aginDistributeFormRef?.resetFields();
      this.$refs.manualDistributeFormRef?.resetFields();
      this.ismanual = false;
      this.echoAddress = false;
    },
    //重新分配弹框
    cxfpp() {
      this.$refs.aginDistributeFormRef?.resetFields();
      this.$refs.manualDistributeFormRef?.resetFields();
      this.isafresh = false;
      this.isolationPointName = "";
      this.isolationPointId = "";
      this.isolationPointAddress = "";
      this.driverUser = "";
      this.driverUserId = "";
      this.communityUser = "";
      this.communityUserId = "";
      this.communityName = "";
      this.communityOrgCode = "";
      this.communityOrgId = "";
    },

    handleClick(row) {
      this.isShowDetail = true;
      //修改为查看流调登记ID
      let rowInfo = { id: "" };
      rowInfo.id = row.regId;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(rowInfo);
      });
    },
    //分页
    handleSizeChange2(val) {
      this.pageSize = val;
      this.queryTask();
    },
    handleCurrentChange2(val) {
      this.pageNumber = val;
      this.queryTask();
    },
    //确定异常弹框
    confirmUnusual(id) {
      this.isUnusual = true;
      // console.log(id, 'id')
      this.taskId = id;
    },
    //确定异常弹框关闭
    closeUnusualDialog() {
      this.isUnusual = false;
    },
    //确定异常弹框确认
    async confirmUnusualDialog() {
      this.isUnusual = false;
      const params = { id: this.taskId };
      // console.log(params)
      const { data } = await confirmExceptionApi(params);
      if (data.code == "200") {
        this.$message({
          message: data.msg,
          type: "success",
        });
        this.queryTask();
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.top_input {
  display: flex;
}

.top_table {
  margin-top: 10px;
  margin-bottom: 20px;
}

.top_operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog_msg {
  display: flex;
  margin-bottom: 10px;
}

.dialog_msg_name {
  margin-right: 14px;

  .dialog_msg_name_id {
    padding: 4px;
    color: #999999;
  }
}

.dialog_msg_type {
  margin-right: 14px;
  padding: 4px;
  background-color: #f90101;
  color: #fff;
}

.dialog_msg_type02 {
  margin-right: 14px;
}

.dialog_msg_typebsmj {
  margin-right: 14px;
  padding: 4px;
  color: #fff;
  background-color: #0079fe;
}

.sdform {
  margin-top: 10px;
}

table {
  border-collapse: collapse;
}

table tr td {
  border: 1px solid #d4dedd;
}

.td1 {
  padding: 0 10px;

  span {
    background-color: #0079fe;
    color: #fff;
  }
}

.td0,
.td2,
.td3,
.td4,
.td5,
.td6 {
  padding: 10px 10px;
}

.td6 {
  display: flex;
}

.content_page {
  // position: fixed;
  float: right;
  bottom: 1px;
  right: 0;
  margin-top: 10px;
  height: 40px;
}

.content_page1 {
  // position: fixed;
  float: right;
  bottom: 1px;
  // right: 0;
  margin-top: 10px;
  height: 40px;
}
</style>